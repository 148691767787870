import React from "react";
import HomeSlider from "./home/homeSlider";
import CompanyCaption from "./home/companyCaption";
import OurServices from "./home/ourServices";
import OurProcess from "./home/ourProcess";
import OurEvents from "./home/ourEvents";
import OurTeam from "./home/ourTeam";
import WeSay from "./home/weSay";
import OurPartners from "./ourPartners";

const Home = () => {
  return (
    <React.Fragment>
      <HomeSlider />
      <CompanyCaption />
      <OurServices />
      <OurProcess />
      <OurEvents />
      <OurTeam />
      <WeSay />
      <OurPartners />
    </React.Fragment>
  );
};

export default Home;
