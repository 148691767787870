import React, { Component } from "react";
import { getWidget } from "../services/widgetService";
import english from "../images/english.png";
import french from "../images/french.png";
import german from "../images/german.png";

class TopBar extends Component {
  state = {
    topWidget: ""
  };

  async componentDidMount() {
    const { data: topWidget } = await getWidget("header-topbar-widget");

    this.setState({ topWidget });
  }
  render() {
    const { topWidget } = this.state;

    return (
      <div id="top-bar">
        <div className="container">
          <div className="row">
            <div
              className="col-md-9 col-xs-12"
              dangerouslySetInnerHTML={{ __html: topWidget.rendered }}
            />
            <div className="col-xs-12 col-md-3 xs-display-none">
              <ul className="top-social-icon">
                <li>
                  <a href="/">
                    <img src={english} alt="" />
                  </a>
                </li>
                <li>
                  <a href="/">
                    <img src={french} alt="" />
                  </a>
                </li>
                <li>
                  <a href="/">
                    <img src={german} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopBar;
