import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getWidget } from "../services/widgetService";
import { getMenu } from "../services/menuService";

class NavBar extends Component {
  state = {
    menu: [],
    logoWidget: ""
  };

  async componentDidMount() {
    const { data } = await getMenu(392);
    const menu = data.items.map(m => (
      <li key={m.id} className={m.children && "has-sub"}>
        {m.children && <span className="submenu-button" />}
        <Link className="nav-item" to={`/${m.object_slug}`}>
          {m.title}
        </Link>
        {m.children && <ul>{this.subMenu(m.children)}</ul>}
      </li>
    ));
    this.setState({ menu });

    const { data: logoWidget } = await getWidget("header-logo-widget");
    this.setState({
      logoWidget: logoWidget.rendered
    });
  }

  subMenu = children =>
    children.map(c =>
      c.object_slug === "our-team" ? (
        <li key={c.id}>
          <a href={`/${c.object_slug}`}>{c.title}</a>
        </li>
      ) : (
        <li key={c.id}>
          <a href={`/about-galitein-technologies#${c.object_slug}`}>
            {c.title}
          </a>
        </li>
      )
    );

  render() {
    const { menu, logoWidget } = this.state;

    return (
      <div className="navbar-default">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-lg-12">
              <div className="menu_area alt-font">
                <nav className="navbar navbar-expand-lg navbar-light no-padding">
                  <div className="navbar-header navbar-header-custom">
                    <a className="navbar-brand logodefault" href="/">
                      <img id="logo" src={logoWidget} alt="logo" />
                    </a>
                  </div>

                  <div className="navbar-toggler" />

                  <ul className="navbar-nav ml-auto" id="nav">
                    {menu}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NavBar;
