import React from "react";

const serviceMethodology = props => {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-12 mb-3">
            <div className="section-heading left half">
              <h2 className="title-style2">Methodology</h2>
            </div>
            <p>{props.methodologyheader.rendered}</p>
          </div>
        </div>
        <div className="row">
          {props.methodologyData.map(m => (
            <div
              key={m.id}
              className="col-lg-4 col-md-6 margin-30px-bottom xs-margin-20px-bottom"
            >
              <div className="services-block-three">
                <span>
                  <div className="padding-15px-bottom">
                    <img
                      src={m.featured_image_url}
                      alt=""
                      className="width-20"
                    />
                  </div>
                  <h4>{m.title.rendered.replace(/&#038;/, "&")}</h4>
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default serviceMethodology;
