import http from "./httpService";

const apiEndpoint = "/posts/";

function postCatUrl(id) {
  return `${apiEndpoint}?categories=${id}`;
}

function postUrl(id) {
  return `${apiEndpoint}${id}`;
}

function postSlugUrl(slug) {
  return `${apiEndpoint}?slug=${slug}`;
}

export function getPostsByCatID(catId) {
  return http.get(postCatUrl(catId));
}

export function getPostsByID(id) {
  return http.get(postUrl(id));
}

export function getPostsBySlug(slug) {
  return http.get(postSlugUrl(slug));
}
