import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_WIDGET_API_URL;

function widgetUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getWidget(widgetId) {
  return http.get(widgetUrl(widgetId));
}
