import React, { Component } from "react";
import { getPostsByCatID } from "../services/postsService";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class OurPartners extends Component {
  state = {
    partners: []
  };

  async componentDidMount() {
    const { data } = await getPostsByCatID("29&page=1&per_page=100");
    const partners = data.map(p => (
      <div key={p.id} className="item">
        <img alt="partner" src={p.featured_image_url} />
      </div>
    ));
    this.setState({ partners });
  }

  render() {
    const options = {
      items: 5,
      autoplay: true,
      slideBy: 1,
      dots: false
    };
    // const { partners } = this.state;

    return (
      <div className="section-clients">
        <div className="container">
          <div className="section-heading">
            <h3 className="title-style2">Our Partners</h3>
          </div>
          <OwlCarousel className="owl-theme" {...options}>
            <div className="item">
              <img
                alt="partner"
                src="http://www.galitein.com/galitein-new/img/partners/partner1.png"
                style={{ width: "158px" }}
              />
            </div>
            <div className="item">
              <img
                alt="partner"
                src="http://www.galitein.com/galitein-new/img/partners/partner2.png"
                style={{ width: "158px" }}
              />
            </div>
            <div className="item">
              <img
                alt="partner"
                src="http://www.galitein.com/galitein-new/img/partners/partner3.png"
                style={{ width: "158px" }}
              />
            </div>
            <div className="item">
              <img
                alt="partner"
                src="http://www.galitein.com/galitein-new/img/partners/partner4.png"
                style={{ width: "158px" }}
              />
            </div>
            <div className="item">
              <img
                alt="partner"
                src="http://www.galitein.com/galitein-new/img/partners/partner5.png"
                style={{ width: "158px" }}
              />
            </div>
            <div className="item">
              <img
                alt="partner"
                src="http://www.galitein.com/galitein-new/img/partners/partner6.png"
                style={{ width: "158px" }}
              />
            </div>
            <div className="item">
              <img
                alt="partner"
                src="http://www.galitein.com/galitein-new/img/partners/partner7.png"
                style={{ width: "158px" }}
              />
            </div>
            <div className="item">
              <img
                alt="partner"
                src="http://www.galitein.com/galitein-new/img/partners/partner8.png"
                style={{ width: "158px" }}
              />
            </div>
            <div className="item">
              <img
                alt="partner"
                src="http://www.galitein.com/galitein-new/img/partners/partner9.png"
                style={{ width: "158px" }}
              />
            </div>
            {/* {partners.map(post => (
                <div key={post.id}>
                  <p>{post.title.rendered}</p>
                </div>
              ))} */}
          </OwlCarousel>
        </div>
      </div>
    );
  }
}

export default OurPartners;
