import React, { Component } from "react";
import { getWidget } from "../services/widgetService";
import { getPage } from "../services/pagesService";
import { getPostsByCatID } from "../services/postsService";
import SubHeader from "../common/subHeader";
import HiringList from "./hiringList";

class Careers extends Component {
  state = {
    coverImage: "",
    careerData: "",
    openingIn: [],
    openingDe: []
  };

  async componentDidMount() {
    const { data: coverImage } = await getWidget("careers-cover-widget");
    const { data: careerData } = await getPage(866);
    const { data: openingIn } = await getPostsByCatID("189&page=1&per_page=50");
    const { data: openingDe } = await getPostsByCatID("28&page=1&per_page=50");

    this.setState({
      coverImage,
      careerData: careerData.content.rendered,
      openingIn,
      openingDe
    });
  }

  render() {
    const { coverImage, careerData, openingIn, openingDe } = this.state;
    const propsUrl = this.props.match.url;

    return (
      <React.Fragment>
        <SubHeader name="Career" coverImage={coverImage.rendered} />
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 sm-margin-30px-bottom xs-margin-20px-bottom">
                <div className="section-heading left">
                  <h6>Send us your feedback</h6>
                </div>
                <div className="contact-form-box margin-30px-top" />
              </div>

              <div className="col-lg-6">
                <div className="contact-info-box padding-30px-left sm-no-padding">
                  <div className="row">
                    <div
                      className="col-12"
                      dangerouslySetInnerHTML={{
                        __html: careerData
                      }}
                    />
                    {openingIn.length !== 0 && (
                      <HiringList
                        title="India"
                        openingData={openingIn}
                        propsUrl={propsUrl}
                      />
                    )}
                    {openingDe.length !== 0 && (
                      <HiringList
                        title="Germany"
                        openingData={openingDe}
                        propsUrl={propsUrl}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Careers;
