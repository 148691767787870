import React, { Component } from "react";
import { getWidget } from "../services/widgetService";
import { getPostsByCatID } from "../services/postsService";
import SubHeader from "../common/subHeader";

class Services extends Component {
  state = {
    coverImage: "",
    subHeader: "",
    services: []
  };
  async componentDidMount() {
    const { data: coverImage } = await getWidget("services-cover-widget");
    const { data: subHeader } = await getWidget("services-subheader-widget");
    const { data: services } = await getPostsByCatID("2&order=asc");

    this.setState({ coverImage, subHeader, services });
  }

  render() {
    const { coverImage, subHeader, services } = this.state;
    return (
      <React.Fragment>
        <SubHeader name="Our Services" coverImage={coverImage.rendered} />
        <section>
          <div
            className="container"
            dangerouslySetInnerHTML={{
              __html: subHeader.rendered
            }}
          />
          <div className="container">
            <div className="row">
              {services.map(s => (
                <div
                  key={s.id}
                  className="col-lg-4 col-md-6 margin-30px-bottom sm-margin-20px-bottom"
                >
                  <div className="service-simple">
                    <div className="overflow-hidden">
                      <a href={`our-services/${s.slug}`}>
                        <img alt="img" src={s.featured_image_url} />
                      </a>
                    </div>
                    <div className="service-simple-inner">
                      <h4>
                        <a
                          href={`our-services/${s.slug}`}
                          className="dark-link"
                        >
                          {s.title.rendered}
                        </a>
                      </h4>
                      <div className="separator-line-horrizontal-full bg-medium-gray margin-10px-top margin-20px-bottom" />
                      <p
                        dangerouslySetInnerHTML={{
                          __html: s.excerpt.rendered
                        }}
                      />
                      <a href={`our-services/${s.slug}`} className="butn small">
                        <span>Read More</span>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Services;
