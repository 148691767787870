import http from "./httpService";

const apiEndpoint = "/service-tool/";

function toolUrl(id) {
  return `${apiEndpoint}?our-tools=${id}`;
}

export function getToolByID(Id) {
  return http.get(toolUrl(Id));
}
