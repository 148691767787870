import React from "react";

const Textarea = ({ name, rows, error, ...rest }) => {
  return (
    <div className="col-md-12">
      <textarea {...rest} name={name} id={name} rows={rows} />
      {error && <span className="mfValidation error">{error}</span>}
    </div>
  );
};

export default Textarea;
