import http from "./httpService";

const apiEndpoint = "/methodology/";

function methodologyUrl(id) {
  return `${apiEndpoint}?our-tools=${id}`;
}

export function getMethodologyByID(Id) {
  return http.get(methodologyUrl(Id));
}
