import React, { Component } from "react";
import { getWidget } from "../services/widgetService";
import { getPage } from "../services/pagesService";
import SubHeader from "../common/subHeader";
import ContactForm from "./contactForm";

class Contact extends Component {
  state = {
    coverImage: "",
    subTitle: "",
    contactData: ""
  };

  async componentDidMount() {
    const { data: coverImage } = await getWidget("contact-cover-widget");
    const { data: subTitle } = await getWidget("contact-subheader-widget");
    const { data: contactData } = await getPage(101);
    this.setState({
      coverImage,
      subTitle: subTitle.rendered,
      contactData: contactData.content.rendered
    });
  }

  render() {
    const { coverImage, subTitle, contactData } = this.state;

    return (
      <React.Fragment>
        <SubHeader name="Contact" coverImage={coverImage.rendered} />
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 sm-margin-30px-bottom xs-margin-20px-bottom">
                <div className="section-heading left">
                  <h6>
                    <small>{subTitle}</small>
                  </h6>
                </div>
                <div className="contact-form-box margin-30px-top">
                  <ContactForm />
                </div>
              </div>

              <div className="col-lg-6">
                <div
                  className="contact-info-box padding-30px-left sm-no-padding"
                  dangerouslySetInnerHTML={{
                    __html: contactData
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Contact;
