import React from "react";
import Form from "../common/form";
import { saveContact } from "../services/contactService";

class ContactForm extends Form {
  state = {
    formData: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      message: ""
    },
    errors: {},
    addClass: "mfPlaceHolder default",
    messageData: "",
    errorData: "",
    loading: false
  };

  doSubmit = async () => {
    const formData = { ...this.state.formData };

    await saveContact(formData).then(response => {
      if (response.data.success === true) {
        this.setState({
          loading: false,
          messageData: response.data.message
        });
      }
      if (response.data.success === false) {
        this.setState({
          loading: false,
          errorData: response.data.message
        });
      }
    });
  };

  render() {
    const { messageData, errorData, loading } = this.state;

    return (
      <form onSubmit={this.handleSubmit} className="mailform off2">
        <div className="row">
          {this.renderInput("firstName", "First Name:")}
          {this.renderInput("lastName", "Last Name:")}
          {this.renderInput("phone", "Telephone:")}
          {this.renderInput("email", "Email:")}
          {this.renderTextarea("message", "Message:", 5)}
          {this.renderButton("Send Message", messageData, errorData, loading)}
        </div>
      </form>
    );
  }
}

export default ContactForm;
