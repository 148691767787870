import React from "react";
const Mission = props => {
  return (
    <section id="our-mission">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-12 sm-margin-30px-bottom xs-margin-20px-bottom"
            dangerouslySetInnerHTML={{ __html: props.missionData }}
          />
        </div>
      </div>
    </section>
  );
};

export default Mission;
