import React from "react";

const ServiceHeader = props => {
  return (
    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading left half">
              <h2 className="title-style2">{props.serviceTitle}</h2>
            </div>
            <p
              dangerouslySetInnerHTML={{
                __html: props.serviceData
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceHeader;
