import React, { Component } from "react";
import { getTeam } from "../../services/teamService";
import TeamFormat from "../../common/teamFormat";

class OurTeam extends Component {
  state = {
    team: []
  };

  async componentDidMount() {
    const { data: team } = await getTeam("?page=1&per_page=4&order=asc");
    this.setState({ team });
  }
  render() {
    const { team } = this.state;

    return (
      <section>
        <div className="container">
          <div className="section-heading">
            <h3 className="title-style2">Our Team</h3>
          </div>
          <div className="row">
            <TeamFormat team={team} />
          </div>
          <div className="row">
            <div className="col-sm-12 text-center">
              <a href="/our-team" className="butn medium theme">
                <span className="alt-font">View All</span>
                <i className="fas fa-angle-right font-size16 sm-font-size14 text-white margin-10px-left" />
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default OurTeam;
