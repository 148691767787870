import React, { Component } from "react";
import { getPortfolio } from "../services/portfolioService";
import { getPortfolioTaxonomy } from "../services/portfolioTaxonomyService";
import { getWidget } from "../services/widgetService";
import SubHeader from "../common/subHeader";

class Portfolio extends Component {
  state = {
    coverImage: "",
    subHeader: "",
    portfolio: [],
    terms: [],
    selectedTerm: null
  };
  async componentDidMount() {
    const { data: coverImage } = await getWidget("portfolio-cover-widget");
    const { data: subHeader } = await getWidget("portfolio-subheader-widget");
    const { data: portfolio } = await getPortfolio("?page=1&per_page=100");
    const { data: terms } = await getPortfolioTaxonomy();
    this.setState({ coverImage, subHeader, portfolio, terms });
  }

  handlePortfolioTermSelect = item => {
    this.setState({ selectedTerm: item.id });
  };

  getSelectedData = () => {
    const { selectedTerm } = this.state;

    let { portfolio } = this.state;

    if (selectedTerm) {
      portfolio = portfolio.filter(p => p["our-portfolio"][0] === selectedTerm);
    }
    if (selectedTerm === "*") {
      portfolio = this.state.portfolio;
    }

    return { portfolio };
  };

  render() {
    const { coverImage, subHeader, terms, selectedTerm } = this.state;

    const { portfolio } = this.getSelectedData();

    return (
      <React.Fragment>
        <SubHeader name="Projects" coverImage={coverImage.rendered} />
        <section>
          <div
            className="container"
            dangerouslySetInnerHTML={{
              __html: subHeader.rendered
            }}
          />

          <div className="container-fluid">
            <div className="row">
              <div className="filtering col-sm-12 text-center">
                <span
                  className={
                    selectedTerm === "*" || !selectedTerm ? "active" : ""
                  }
                  onClick={() => {
                    const all = { id: "*" };
                    this.handlePortfolioTermSelect(all);
                  }}
                >
                  Show All
                </span>
                {terms.map(t => (
                  <span
                    key={t.id}
                    className={t.id === selectedTerm ? "active" : ""}
                    onClick={() => this.handlePortfolioTermSelect(t)}
                  >
                    {t.name}
                  </span>
                ))}
              </div>
              <div className="gallery portfolio-page text-center width-100">
                <div className="container-fluid">
                  <div className="row">
                    {portfolio.map(p => (
                      <div key={p.id} className="col-md-4 items">
                        <div className="project-grid">
                          <div className="project-grid-img">
                            <img
                              alt="img"
                              src={p["post-meta-fields"]["wpcf-image"]}
                            />
                          </div>
                          <div className="project-grid-overlay">
                            <div
                              className="width-100"
                              dangerouslySetInnerHTML={{
                                __html: p.content.rendered
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Portfolio;
