import http from "./httpService";

const apiEndpoint = "/portfolio-old";

function solutionUrl(id) {
  return `${apiEndpoint}?our-tools=${id}`;
}

export function getPortfolio(para) {
  return http.get(apiEndpoint + para);
}

export function getsolutionByID(Id) {
  return http.get(solutionUrl(Id));
}
