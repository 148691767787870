import React, { Component } from "react";
import { getWidget } from "../services/widgetService";
import { getPostsBySlug } from "../services/postsService";
import SubHeader from "../common/subHeader";

class HiringDetail extends Component {
  state = {
    coverImage: "",
    postTitle: "",
    post: []
  };
  async componentDidMount() {
    const { data: coverImage } = await getWidget("careers-detail-cover-widget");
    const { data: post } = await getPostsBySlug(this.props.match.params.slug);

    this.setState({ coverImage, post, postTitle: post[0].title.rendered });
  }
  render() {
    const { coverImage, post, postTitle } = this.state;
    return (
      <React.Fragment>
        <SubHeader
          name={postTitle}
          subName="Career"
          subLink="/careers"
          coverImage={coverImage.rendered}
        />
        <section>
          <div class="container">
            <div class="row">
              {post.map(p => (
                <div
                  class="col-lg-12 sm-margin-30px-bottom xs-margin-20px-bottom"
                  dangerouslySetInnerHTML={{
                    __html: p.content.rendered
                  }}
                />
              ))}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default HiringDetail;
