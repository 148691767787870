import http from "./httpService";

const apiEndpoint = "/pages";

function pageUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getPage(pageId) {
  return http.get(pageUrl(pageId));
}
