import http from "./httpService";

const apiEndpoint = process.env.REACT_APP_MENU_API_URL;

function menuUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getMenu(menuId) {
  return http.get(menuUrl(menuId));
}
