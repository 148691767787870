import React, { Component } from "react";
import { getWidget } from "../services/widgetService";
import { getPostsBySlug } from "../services/postsService";
import SubHeader from "../common/subHeader";

class SingleBlog extends Component {
  state = {
    coverImage: "",
    post: []
  };

  async componentDidMount() {
    const { data: coverImage } = await getWidget("single-blog-cover-widget");
    const { data: post } = await getPostsBySlug(this.props.match.params.slug);
    this.setState({ coverImage, post });
  }

  render() {
    const { coverImage, post } = this.state;

    return (
      <React.Fragment>
        <SubHeader name="Blog Post" coverImage={coverImage.rendered} />
        {post.map(p => (
          <section key={p.id} className="blogs">
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1 padding-30px-right xs-padding-15px-right sm-margin-30px-bottom">
                  <div className="posts">
                    <div className="post">
                      <div className="post-img">
                        <div className="width-100">
                          <img src={p.featured_image_url} alt="" />
                        </div>
                      </div>
                      <div className="content">
                        <div className="post-meta">
                          <div className="post-title">
                            <h5>{p.title.rendered}</h5>
                          </div>
                          <ul className="meta">
                            <li>
                              <span>
                                <i
                                  className="fas fa-calendar-alt"
                                  aria-hidden="true"
                                />{" "}
                                {p.publish_date}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div
                          className="post-cont"
                          dangerouslySetInnerHTML={{
                            __html: p.content.rendered
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ))}
      </React.Fragment>
    );
  }
}

export default SingleBlog;
