import React, { Component } from "react";
import Input from "../common/input";
import Textarea from "../common/textarea";

class Form extends Component {
  state = {
    formData: {},
    errors: {},
    loading: false
  };

  clear = () => {
    const { formData } = this.state;
    formData.firstName = "";
    formData.lastName = "";
    formData.email = "";
    formData.phone = "";
    formData.message = "";
    return formData;
  };

  validate = () => {
    const errors = {};
    const { formData } = this.state;

    if (formData.firstName.trim() === "")
      errors.firstName = "Please enter first name.";

    if (formData.lastName.trim() === "")
      errors.lastName = "Please enter last name.";

    if (formData.email.trim() === "") {
      errors.email = "Please enter your email.";
    } else if (
      !formData.email.match(
        /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,4})$/
      )
    ) {
      errors.email = "Email you have entered is not valid.";
    }

    if (
      !formData.phone.match(/^(?:[+\d].*\d|\d)$/) &&
      formData.phone.length !== 0
    ) {
      errors.phone = "Enter valid phone number.";
    }

    if (formData.message.trim() === "")
      errors.message = "Please enter message.";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  validateProperty = ({ name, value }) => {
    if (name === "firstName") {
      if (value.trim() === "") return "Please enter first name.";
    }
    if (name === "lastName") {
      if (value.trim() === "") return "Please enter last name.";
    }
    if (name === "email") {
      if (value.trim() === "") return "Please enter your email.";
    }
    if (name === "message") {
      if (value.trim() === "") return "Please enter message.";
    }
  };

  handleSubmit = e => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;
    this.setState({ loading: true });
    this.doSubmit();
    this.clear();
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const formData = { ...this.state.formData };
    formData[input.name] = input.value;
    this.setState({ formData, errors });
  };

  renderButton(label, messageData, errorData, loading) {
    let classes = "butn mfProgress";
    if (errorData !== "") classes += " fail";
    if (messageData !== "") classes += " success";
    if (loading === true) classes += " sending";
    return (
      <div className="mfControls col-md-12">
        <button type="submit" className={classes}>
          <span className="cnt">{label}</span>
          <span className="loader" />
          {errorData !== "" && <span className="msg">{errorData}</span>}
          {messageData !== "" && <span className="msg">{messageData}</span>}
        </button>
        {/* <button className="butn mfProgress fail">
          {label}
          {errorData !== "" && <span className="msg">{errorData}</span>}
        </button>
        {loading === true && (
          <span>
            <i className="fa fa-spinner fa-spin" /> Loading...
          </span>
        )} */}
      </div>
    );
  }

  renderInput(name, placeholder, type = "text") {
    const { formData, errors } = this.state;
    return (
      <Input
        name={name}
        type={type}
        value={formData[name]}
        placeholder={placeholder}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderTextarea(name, placeholder, rows) {
    const { formData, errors } = this.state;
    return (
      <Textarea
        name={name}
        value={formData[name]}
        rows={rows}
        placeholder={placeholder}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }
}

export default Form;
