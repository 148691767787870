import React from "react";

const ServiceSolution = props => {
  return (
    <section className="bg-light-gray">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading half left">
              <h2 className="title-style2">Our Solutions</h2>
            </div>
          </div>
        </div>
        <div className="row text-center">
          {props.solutionData.map(s => (
            <div key={s.id} className="col-lg-4 col-md-6 items consulting">
              <div className="project-grid">
                <div className="project-grid-img">
                  <img alt="img" src={s["post-meta-fields"]["wpcf-image"]} />
                </div>
                <div className="project-grid-overlay">
                  <div
                    className="width-100"
                    dangerouslySetInnerHTML={{
                      __html: s.content.rendered
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceSolution;
