import React from "react";

const HiringList = props => {
  return (
    <div className="col-md-6">
      <div className="contact-info-section border-none ">
        <h6 className="mb-2">
          <small>{props.title}</small>
        </h6>
        <ul className="list-style-2 no-margin-bottom">
          {props.openingData.map(o => (
            <li key={o.id}>
              <a href={`${props.propsUrl}/${o.slug}`}>{o.title.rendered}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default HiringList;
