import React, { Component } from "react";
import { getPostsByCatID } from "../../services/postsService";

class companyCaption extends Component {
  state = {
    caption: []
  };

  async componentDidMount() {
    const { data } = await getPostsByCatID("212&page=1&per_page=1&order=asc");

    const caption = data.map(c => (
      <section key={c.id}>
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-12">
              <div className="section-heading half">
                <h2 className="title-style2 font-size23">{c.title.rendered}</h2>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 display-table md-margin-five-top sm-margin-30px-bottom xs-no-margin-top">
              <div
                className="display-table-cell vertical-align-middle width-100"
                dangerouslySetInnerHTML={{ __html: c.content.rendered }}
              />
            </div>
            <div className="col-lg-4 col-md-9 col-xs-12 offset-lg-1 offset-md-0 about-carousel home-text-img text-center display-table">
              <div className="display-table-cell vertical-align-middle">
                <img
                  src={c["post-meta-fields"]["wpcf-captionimage1"]}
                  alt=""
                  className="width-50 float-left padding-5px-right border-radius-5"
                />
                <img
                  src={c["post-meta-fields"]["wpcf-captionimage2"]}
                  alt=""
                  className="width-50 float-left padding-5px-left border-radius-5"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    ));

    this.setState({ caption });
  }

  render() {
    const { caption } = this.state;
    return <div>{caption}</div>;
  }
}

export default companyCaption;
