import React from "react";

const TeamFormat = props => {
  return props.team.map(t => (
    <div
      key={t.id}
      className="col-lg-3 col-md-6 col-xs-12 sm-margin-20px-bottom text-center"
    >
      <div className="team-style1">
        <div className="team-member-img">
          <img className="img-responsive" src={t.featured_image_url} alt="" />
        </div>
        <div className="text-center margin-25px-top margin-15px-bottom  padding-25px-bottom">
          <div className="text-extra-dark-gray font-weight-600 text-uppercase font-size14 alt-font">
            {t.title.rendered}
          </div>
          <div
            className="text-uppercase font-size11"
            dangerouslySetInnerHTML={{
              __html: t.content.rendered
            }}
          />
        </div>
      </div>
    </div>
  ));
};

export default TeamFormat;
