import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { getWidget } from "../services/widgetService";
import { getMenu } from "../services/menuService";
import english from "../images/english.png";
import french from "../images/french.png";
import german from "../images/german.png";

class Footer extends Component {
  state = {
    inWidget: "",
    deWidget: "",
    quoteWidget: "",
    footerMenu: [],
    copyrightWidget: ""
  };

  async componentDidMount() {
    const { data: inWidget } = await getWidget("footer-india-widget");
    const { data: deWidget } = await getWidget("footer-germany-widget");
    const { data: quoteWidget } = await getWidget(
      "footer-request-quote-widget"
    );
    const { data: copyrightWidget } = await getWidget(
      "footer-copyright-widget"
    );
    const { data } = await getMenu(393);
    const footerMenu = data.items.map(m => (
      <li key={m.id}>
        <NavLink className="nav-item" to={m.object_slug}>
          {m.title}
        </NavLink>
      </li>
    ));
    this.setState({
      inWidget,
      deWidget,
      quoteWidget,
      footerMenu,
      copyrightWidget
    });
  }

  render() {
    const {
      inWidget,
      deWidget,
      quoteWidget,
      footerMenu,
      copyrightWidget
    } = this.state;

    return (
      <footer>
        <div className="container">
          <div className="row">
            <div
              className="col-lg-4 col-md-6 sm-margin-30px-bottom"
              dangerouslySetInnerHTML={{ __html: quoteWidget.rendered }}
            />
            <div className="col-lg-4 col-md-6 sm-margin-30px-bottom">
              <h3 className="footer-title-style2 text-theme-color">
                Contact Details
              </h3>
              <div className="row">
                <div
                  className="col-md-6 no-padding-right xs-padding-15px-right"
                  dangerouslySetInnerHTML={{ __html: inWidget.rendered }}
                />
                <div
                  className="col-md-6 no-padding-right xs-padding-15px-right"
                  dangerouslySetInnerHTML={{ __html: deWidget.rendered }}
                />
                <div className="col-md-12">
                  <strong className="footer-title-style2 text-theme-color">
                    Languages:
                  </strong>
                  <a href="/" className="ml-2">
                    <img src={english} alt="" />
                  </a>
                  <a href="/" className="ml-2">
                    <img src={french} alt="" />
                  </a>
                  <a href="/" className="ml-2">
                    <img src={german} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 offset-lg-1">
              <h3 className="footer-title-style2 text-theme-color">
                Our Company
              </h3>
              <ul className="footer-list xs-margin-5px-bottom">{footerMenu}</ul>
            </div>
          </div>
        </div>
        <div className="footer-bar xs-font-size13">
          <div className="container">
            <div
              className="row"
              dangerouslySetInnerHTML={{ __html: copyrightWidget.rendered }}
            />
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
