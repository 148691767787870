import React, { Component } from "react";

class RequestQuote extends Component {
  state = {};
  render() {
    return (
      <section
        className="page-title-section2 bg-img cover-background"
        data-overlay-dark="7"
        data-background="img/inner-contact-bg.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-left">
              <h1>Request A Quote</h1>
            </div>
            <div className="col-md-12">
              <ul className="text-left">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/">Request A Quote</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default RequestQuote;
