import React, { Component } from "react";
import { getWidget } from "../services/widgetService";
import { getPage } from "../services/pagesService";
import SubHeader from "../common/subHeader";
import Mission from "./mission";
import ProcessHeader from "./processHeader";
import ProcessData from "./processData";

class About extends Component {
  state = {
    coverImage: "",
    missionData: ""
  };

  async componentDidMount() {
    const { data: coverImage } = await getWidget("about-cover-widget");
    const { data: missionData } = await getPage(788);
    const { data: processHeader } = await getPage(2);

    this.setState({
      coverImage,
      missionData: missionData.content.rendered,
      processHeader: processHeader.content.rendered
    });
  }

  render() {
    const { coverImage, missionData, processHeader } = this.state;

    return (
      <React.Fragment>
        <SubHeader name="About" coverImage={coverImage.rendered} />
        <Mission missionData={missionData} />
        <section className="bg-very-light-gray" id="our-process">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 sm-margin-30px-bottom xs-margin-20px-bottom">
                <ProcessHeader processHeader={processHeader} />
                <div className="row mt-5">
                  <ProcessData />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default About;
