import React, { Component } from "react";
import { getPostsByCatID } from "../../services/postsService";

class OurEvents extends Component {
  state = {
    events: []
  };

  async componentDidMount() {
    const { data } = await getPostsByCatID("332");

    const events = data.map((value, index, array) => {
      if (index % 2 !== 0) {
        value = { ...value };
        value["evenData"] = "even";
      }
      return value;
    });

    this.setState({ events });
  }
  render() {
    const { events } = this.state;

    return (
      <section className="bg-light-gray">
        <div className="container">
          <div className="section-heading">
            <h3 className="title-style2">Our Events</h3>
          </div>
          <div className="row">
            <div className="col-lg-12 history-mid-line">
              {events.map(e => (
                <div key={e.id} className="row mb-5 d-flex align-items-center">
                  {!e.evenData && (
                    <div
                      className="col-md-4 order-2 history-years text-md-right"
                      dangerouslySetInnerHTML={{
                        __html: e["post-meta-fields"]["wpcf-event-date"]
                      }}
                    />
                  )}
                  {e.evenData && (
                    <div className="col-md-4 mb-3 order-md-1 order-3 text-md-right">
                      <h4 className="font-size22 font-weight-600 mb-2">
                        {e.title.rendered}
                      </h4>
                      <p
                        className="history-text-right"
                        dangerouslySetInnerHTML={{ __html: e.excerpt.rendered }}
                      />
                    </div>
                  )}

                  <div className="col-md-4 mb-3 order-md-2 order-1">
                    <span className="history-img text-center">
                      <img
                        src={e.featured_image_url}
                        alt=""
                        className="rounded-circle width-70"
                      />
                    </span>
                  </div>

                  {!e.evenData && (
                    <div className="col-md-4 mb-3 order-md-3 order-3">
                      <h4 className="font-size22 font-weight-600 history-title mb-2">
                        {e.title.rendered}
                      </h4>
                      <p
                        className="history-text"
                        dangerouslySetInnerHTML={{ __html: e.excerpt.rendered }}
                      />
                    </div>
                  )}
                  {e.evenData && (
                    <div
                      className="col-md-4 order-md-3 order-2 history-years"
                      dangerouslySetInnerHTML={{
                        __html: e["post-meta-fields"]["wpcf-event-date"]
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default OurEvents;
