import React from "react";

const Input = ({ name, error, ...rest }) => {
  return (
    <div className="col-md-6">
      <input {...rest} name={name} id={name} />
      {error && <span className="mfValidation error">{error}</span>}
    </div>
  );
};

export default Input;
