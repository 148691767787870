import React, { Component } from "react";
import { getWidget } from "../services/widgetService";
import { getPostsByCatID } from "../services/postsService";
import SubHeader from "../common/subHeader";

class Blogs extends Component {
  state = {
    coverImage: "",
    posts: []
  };

  async componentDidMount() {
    const { data: coverImage } = await getWidget("blogs-cover-widget");
    const { data: posts } = await getPostsByCatID("1&page=1&per_page=200");

    this.setState({ coverImage, posts });
  }

  render() {
    const { coverImage, posts } = this.state;
    return (
      <React.Fragment>
        <SubHeader name="Our Blogs" coverImage={coverImage.rendered} />
        <section>
          <div className="container">
            {posts.map(p => (
              <div key={p.id} className="blog-list-simple">
                <div className="row">
                  <div className="col-md-5 col-sm-12 sm-margin-20px-bottom">
                    <div className="blog-list-simple-img border">
                      <img src={p.featured_image_url} alt="" />
                    </div>
                  </div>
                  <div className="col-md-7 col-sm-12">
                    <div className="blog-list-simple-text">
                      <h4>
                        <a href={`${this.props.match.url}/${p.slug}`} className="dark-link">
                          {p.title.rendered}
                        </a>
                      </h4>
                      <ul className="meta">
                        <li>
                          <span>
                            <i
                              aria-hidden="true"
                              className="fas fa-calendar-alt"
                            />{" "}
                            {p.publish_date}
                          </span>
                        </li>
                      </ul>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: p.excerpt.rendered
                        }}
                      />
                      <div className="text-left margin-10px-top">
                        <a
                          href={`${this.props.match.url}/${p.slug}`}
                          className="butn small"
                        >
                          <span>Read More</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Blogs;
