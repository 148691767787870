import React, { Component } from "react";
import { getHomeSlider } from "../../services/homeSliderService";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class HomeSlider extends Component {
  state = {
    slider: []
  };

  async componentDidMount() {
    const { data } = await getHomeSlider();
    const slider = data.map(m => (
      <div key={m.id} className="item">
        <div
          className="text-center item bg-img"
          data-overlay-dark="7"
          data-background={m["featured_image_url"]}
        >
          <div className="absolute-middle-center caption">
            <div className="overflow-hidden">
              <h3 className="alt-font font-size28 sm-font-size18 text-white no-margin">
                {m["post-meta-fields"]["wpcf-first-heading"]}
              </h3>
              <h1 className="text-white">
                {m["post-meta-fields"]["wpcf-second-heading"]}
              </h1>
              <p className="margin-30px-bottom sm-margin-20px-bottom sm-display-none">
                {m["post-meta-fields"]["wpcf-third-heading"]}
              </p>
              <a href="details.html" className="butn medium theme">
                <span className="alt-font">
                  {m["post-meta-fields"]["wpcf-more-details-btn-name"]}
                </span>
                <i className="fas fa-angle-right font-size16 sm-font-size14 text-white margin-10px-left" />
              </a>
            </div>
          </div>
        </div>
      </div>
    ));
    this.setState({ slider });
  }
  render() {
    const options = {
      items: 1,
      autoplay: true,
      slideBy: 1,
      dots: true
    };
    // const { slider } = this.state;
    // console.log(slider);
    return (
      <div className="container-fluid full-screen">
        <div className="row slider-fade">
          <OwlCarousel className="owl-theme" {...options}>
            <div className="item">
              <div
                className="text-center item bg-img"
                data-overlay-dark="7"
                data-background="http://www.galitein.com/galitein-new/img/slider/cebit-2018.jpg"
              >
                <img
                  src="http://www.galitein.com/galitein-new/img/slider/cebit-2018.jpg"
                  alt="cebit-2018"
                  title="Cebit-2018"
                  className="item bg-img"
                />
                <div className="absolute-middle-center caption">
                  <div className="overflow-hidden">
                    <h3 className="alt-font font-size28 sm-font-size18 text-white no-margin">
                      Cebit
                    </h3>
                    <h1 className="text-white">Attended CeBIT 2018</h1>
                    <p className="margin-30px-bottom sm-margin-20px-bottom sm-display-none">
                      We are attending International events every year.
                    </p>
                    <a href="/" className="butn medium theme">
                      <span className="alt-font">More Details</span>
                      <i className="fas fa-angle-right font-size16 sm-font-size14 text-white margin-10px-left" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div
                className="text-center item bg-img"
                data-overlay-dark="7"
                data-background="http://www.galitein.com/galitein-new/img/slider/cebit-2018.jpg"
              >
                <img
                  src="http://www.galitein.com/galitein-new/img/slider/romania-bg-3.jpg"
                  alt="cebit-2018"
                  title="Cebit-2018"
                  className="item bg-img"
                />
                <div className="absolute-middle-center caption">
                  <div className="overflow-hidden">
                    <h3 className="alt-font font-size28 sm-font-size18 text-white no-margin">
                      Event Romania
                    </h3>
                    <h1 className="text-white">
                      Attended SymfonyCon Cluj <br />
                      Event Romania
                    </h1>
                    <p className="margin-30px-bottom sm-margin-20px-bottom sm-display-none">
                      We are attending International events every year.
                    </p>
                    <a href="/" className="butn medium theme">
                      <span className="alt-font">More Details</span>
                      <i className="fas fa-angle-right font-size16 sm-font-size14 text-white margin-10px-left" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
    );
  }
}

export default HomeSlider;
