import React, { Component } from "react";
import { getWidget } from "../services/widgetService";
import { getPostsBySlug } from "../services/postsService";
import { getToolByID } from "../services/toolsService";
import { getMethodologyByID } from "../services/methodologyService";
import { getsolutionByID } from "../services/portfolioService";
import ServiceHeader from "./ourservices/serviceHeader";
import ServiceTools from "./ourservices/serviceTools";
import ServiceMethodology from "./ourservices/serviceMethodology";
import ServiceSolution from "./ourservices/serviceSolution";
import SubHeader from "../common/subHeader";

class ServiceDetail extends Component {
  state = {
    coverImage: "",
    serviceTitle: "",
    serviceData: "",
    toolData: [],
    methodologyData: [],
    methodologyheader: "",
    solutionData: []
  };

  async componentDidMount() {
    const { data: coverImage } = await getWidget("services-cover-widget");
    const { data: serviceContent } = await getPostsBySlug(
      this.props.match.params.slug
    );

    const toolId = serviceContent[0].tool_id;

    if (toolId !== null) {
      const { data: toolData } = await getToolByID(toolId);
      const { data: methodologyheader } = await getWidget(
        "methodology-subheader-widget"
      );
      const { data: methodologyData } = await getMethodologyByID(toolId);
      const { data: solutionData } = await getsolutionByID(toolId);

      this.setState({
        toolData,
        methodologyheader,
        methodologyData,
        solutionData
      });
    }

    this.setState({
      coverImage,
      serviceTitle: serviceContent[0].title.rendered,
      serviceData: serviceContent[0].content.rendered
    });
  }

  render() {
    const {
      coverImage,
      serviceTitle,
      serviceData,
      toolData,
      methodologyheader,
      methodologyData,
      solutionData
    } = this.state;

    return (
      <React.Fragment>
        <SubHeader
          name={serviceTitle}
          subName="Our Services"
          subLink="/our-services"
          coverImage={coverImage.rendered}
        />

        <ServiceHeader serviceTitle={serviceTitle} serviceData={serviceData} />

        {toolData.length !== 0 && <ServiceTools toolData={toolData} />}

        {methodologyData.length !== 0 && (
          <ServiceMethodology
            methodologyheader={methodologyheader}
            methodologyData={methodologyData}
          />
        )}

        {solutionData.length !== 0 && (
          <ServiceSolution solutionData={solutionData} />
        )}
      </React.Fragment>
    );
  }
}

export default ServiceDetail;
