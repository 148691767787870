import React from "react";

const serviceTools = props => {
  return (
    <section className="bg-light-gray">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="section-heading half left">
              <h2 className="title-style2">Our Tools</h2>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          {props.toolData.map(t => (
            <div
              key={t.id}
              className="col-md-4 sm-margin-20px-bottom blog-style1 margin-30px-bottom"
            >
              <div className="item text-center">
                <div className="post-img">
                  <img src={t.featured_image_url} alt="" />
                </div>
                <div className="content">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t.content.rendered
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default serviceTools;
