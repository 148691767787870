import React from "react";
import { Link } from "react-router-dom";

const SubHeader = props => {
  return (
    <section
      className="page-title-section2 bg-img cover-background"
      data-overlay-dark="7"
      style={{ backgroundImage: `url(${props.coverImage})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-left">
            {props.subName &&
              props.subName !== "Career" &&
              props.subName !== "About" && <h1>{props.subName}</h1>}

            {(!props.subName ||
              props.subName === "Career" ||
              props.subName === "About") && <h1>{props.name}</h1>}
          </div>
          <div className="col-md-12">
            <ul className="text-left">
              <li>
                <a href="/">Home</a>
              </li>
              {props.subName && (
                <li>
                  <Link to={props.subLink}>{props.subName}</Link>
                </li>
              )}
              <li>
                <button className="page-title">{props.name}</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubHeader;
