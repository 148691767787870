import React, { Component } from "react";
import { getPostsByCatID } from "../../services/postsService";

class OurServices extends Component {
  state = {
    services: []
  };

  async componentDidMount() {
    const { data } = await getPostsByCatID(2);

    const services = data.map(s => (
      <div key={s.id} className="col-md-4 col-sm-6">
        <div className="project-grid text-center services-block-three">
          <a href={`our-services/${s.slug}`} className="p-0">
            <div className="project-grid-img">
              <img alt="img" src={s.featured_image_url} />
            </div>
            <div className="project-grid-overlay">
              <div className="width-100">
                <h5 className="p-2 mb-0">{s.title.rendered}</h5>
              </div>
            </div>
          </a>
        </div>
      </div>
    ));
    this.setState({ services });
  }
  render() {
    const { services } = this.state;
    return (
      <section className="bg-light-gray">
        <div className="container">
          <div className="section-heading">
            <h3 className="title-style2">Our Services</h3>
          </div>
          <div className="row">{services}</div>
        </div>
      </section>
    );
  }
}

export default OurServices;
