import React, { Component } from "react";
import { getPostsByCatID } from "../../services/postsService";

class WeSay extends Component {
  state = {
    posts: ""
  };

  async componentDidMount() {
    const { data } = await getPostsByCatID("1&page=1&per_page=3");

    const posts = data.map(p => (
      <div key={p.id} className="col-lg-4 sm-margin-20px-bottom blog-style1">
        <div className="item text-center">
          <div className="post-img">
            <img src={p.featured_image_url} alt="" />
          </div>
          <div className="content">
            <h5 className="margin-15px-bottom">
              <a href={`/our-blogs/${p.slug}`}>{p.title.rendered}</a>
            </h5>
            <p dangerouslySetInnerHTML={{ __html: p.excerpt.rendered }} />
          </div>
        </div>
      </div>
    ));
    this.setState({ posts });
  }

  render() {
    const { posts } = this.state;
    return (
      <section className="bg-light-gray">
        <div className="container">
          <div className="section-heading">
            <h3 className="title-style2">We Say's</h3>
          </div>
          <div className="row">{posts}</div>
        </div>
      </section>
    );
  }
}

export default WeSay;
