import React, { Component } from "react";
import { getPostsByCatID } from "../services/postsService";

class ProcessData extends Component {
  state = {
    process: []
  };

  async componentDidMount() {
    const { data } = await getPostsByCatID("31&order=asc");
    const process = data.map(p => (
      <div key={p.id} className="col-lg-4 col-md-12 mb-4">
        <div className="service-simple">
          <div className="overflow-hidden">
            <img alt="img" src={p.featured_image_url} />
          </div>
          <div className="service-simple-inner">
            <h4>{p.title.rendered.replace(/&#038;/, "&")}</h4>
            <div className="separator-line-horrizontal-full bg-medium-gray margin-10px-top margin-20px-bottom" />
            <p
              dangerouslySetInnerHTML={{
                __html: p.content.rendered
              }}
            />
          </div>
        </div>
      </div>
    ));
    this.setState({ process });
  }

  render() {
    return this.state.process;
  }
}

export default ProcessData;
