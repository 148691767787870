import React, { Component } from "react";
import { getPostsByCatID } from "../../services/postsService";

class OurProcess extends Component {
  state = {
    process: []
  };

  async componentDidMount() {
    const { data } = await getPostsByCatID("31&order=asc");
    const process = data.map(p => (
      <div key={p.id} className="col-lg-4 col-md-6 margin-30px-bottom">
        <div className="project-grid-style2">
          <div className="project-details">
            <img src={p.featured_image_url} alt="" />
            <div className="portfolio-icon">
              <a href="about-galitein-technologies#our-process">
                <i className="fas fa-external-link-alt" />
              </a>
            </div>
            <div className="portfolio-post-border" />
          </div>
          <div className="portfolio-title">
            <h4>{p.title.rendered.replace(/&#038;/, "&")}</h4>
          </div>
        </div>
      </div>
    ));
    this.setState({ process });
  }
  render() {
    const { process } = this.state;

    return (
      <section>
        <div className="container">
          <div className="section-heading">
            <h3 className="title-style2">Our Process</h3>
          </div>
          <div className="row">{process}</div>
        </div>
      </section>
    );
  }
}

export default OurProcess;
