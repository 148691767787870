import React, { Component } from "react";
import { getWidget } from "../services/widgetService";
import { getTeam } from "../services/teamService";
import SubHeader from "../common/subHeader";
import TeamFormat from "../common/teamFormat";

class Team extends Component {
  state = { coverImage: "", team: [] };
  async componentDidMount() {
    const { data: coverImage } = await getWidget("team-cover-widget");
    const { data: team } = await getTeam("?page=1&per_page=50&order=asc");
    this.setState({ coverImage, team });
  }
  render() {
    const { coverImage, team } = this.state;
    return (
      <React.Fragment>
        <SubHeader
          name="Our Team"
          subName="About"
          subLink="/about-galitein-technologies"
          coverImage={coverImage.rendered}
        />
        <section>
          <div className="container">
            <div className="row">
              <TeamFormat team={team} />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Team;
