import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import TopBar from "./components/topBar";
import NavBar from "./components/navBar";
import Home from "./components/home";
import Footer from "./components/footer";
import Portfolio from "./components/portfolio";
import About from "./components/about";
import Services from "./components/services";
import Blogs from "./components/blogs";
import Careers from "./components/careers";
import Contact from "./components/contact";
import RequestQuote from "./components/requestQuote";
import Team from "./components/team";
import SingleBlog from "./components/singleBlog";
import HiringDetail from "./components/hiringDetail";
import ServiceDetail from "./components/serviceDetail";
import "./App.css";

class App extends Component {
  state = {
    scroll: ""
  };
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  nav = React.createRef();

  handleScroll = () => {
    var scroll = window.scrollY;
    this.setState({ scroll });
  };

  render() {
    const { scroll } = this.state;
    let classes = "header-style2";
    if (scroll <= 50) {
      classes += " fixedHeader";
    } else {
      classes += " scrollHeader";
    }

    return (
      <React.Fragment>
        <div className="main-wrapper">
          <header className={classes}>
            <TopBar />
            <NavBar />
          </header>
          <Switch>
            <Route path="/portfolio" component={Portfolio} />
            <Route path="/about-galitein-technologies" component={About} />
            <Route path="/our-team" component={Team} />
            <Route path="/portfolio" component={Portfolio} />
            <Route path="/our-services/:slug" component={ServiceDetail} />
            <Route path="/our-services" component={Services} />
            <Route path="/our-blogs/:slug" component={SingleBlog} />
            <Route path="/our-blogs" component={Blogs} />
            <Route path="/careers/:slug" component={HiringDetail} />
            <Route path="/careers" component={Careers} />
            <Route path="/contact-us" component={Contact} />
            <Route path="/request-a-quote" component={RequestQuote} />
            <Route from="/" exact component={Home} />
            <Redirect to="/" />
          </Switch>

          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default App;
